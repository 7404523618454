import React, { useState, useCallback } from 'react';
import './ResponsiveForm.css'; // Import the CSS file for styling
import axios from 'axios';
import { getDatabase, ref, set,update } from "firebase/database";
import {db} from './firebase';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import useRazorpay from "react-razorpay";

import moment from 'moment';
const cors = require("cors")({origin: true});

const ResponsiveForm = () => {
  const [paymentAmount, setPaymentAmount] = useState(999); // Set your payment amount
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [isValid, setIsValid] = useState(true);
  const [isCoupon, setCoupon] = useState(null);
  const [isCouponSocial, setCouponSocial] = useState(null);
  const [phoneNumberValid, setPhoneNumber] = useState(true);
  const [emailState, setEmailState] = useState(' ');
  const [payment, setPayment] = useState(99900);


    const [attachment, setAttachment] = useState(''); // Attachment content
  const headers = {
    "Access-Control-Allow-Origin": "*",
  }
  

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    phoneNumber: '',
    postalCode: '',
    couponCode:'',
  });

  const [Razorpay] = useRazorpay();

  const handlePayment = () => {

    const options = {
      key: "rzp_live_UoGpaMgcYW2sLm",
      amount: payment,
      currency: "INR",
      name: "Genzvision",
      
      handler: (res) => {
    setLoading(false)
        console.log(res);

          sendEmail(emailState);

      },


      prefill: {
        name: String(formData.firstName),
        email: String(formData.email),
        contact: String(formData.phoneNumber),
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#0C0C37",
      },
      hidden: {
        contact: true,
        email: true,
    }
      
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };


//   const phonePay = async (req, res) => {
//     try {
//         const merchantTransactionId = '123';
//         const data = {
//             merchantId: 'PGTESTPAYUAT',
//             merchantTransactionId: '123',
//             merchantUserId: '9969588138',
//             name: 'Venk',
//             amount: 1 * 100,
//             redirectUrl: `http://genzvision.com/api/status/${merchantTransactionId}`,
//             redirectMode: 'POST',
//             mobileNumber: 9969588138,
//             paymentInstrument: {
//                 type: 'PAY_PAGE'
//             }
//         };
//         const payload = JSON.stringify(data);
//         const payloadMain = btoa(payload);
//         const keyIndex = 1;
//         const string = payloadMain + '/pg/v1/pay' + '099eb0cd-02cf-4e2a-8aca-3e6c6aff0999';
//         const sha256 = CryptoJS.SHA256(string).toString(CryptoJS.enc.Hex);
//         const checksum = sha256 + '###' + keyIndex;

//         const prod_URL = "https://api-preprod.phonepe.com/apis/hermes"
//         const options = {
//             method: 'POST',
//             url: prod_URL,
//             headers: {
//                 accept: 'application/json',
//                 'Content-Type': 'application/json',
//                 'X-VERIFY': checksum,
                
//             },
//             data: {
//                 request: payloadMain
//             }
//         };

//         axios.request(options).then(function (response) {
//             console.log(response.data)
//         })
//         .catch(function (error) {
//             console.error(error);
//         });

//     } catch (error) {
//        console.log('Error ',error)
//     }
// }




// const phonePay = ()=>{
  
// const options = {
//   method:  'post',
//   url: 'https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay',
//   headers: {
//     accept: 'text/plain',
    
//  'Content-Type' : 'application/json' 
//     },
//      data: {
//                   merchantId: 'M223X2ED0YPC5',
//             merchantTransactionId: '123',
//             merchantUserId: '9969588138',
//             name: 'Venk',
//             amount: 1 * 100,
//             redirectUrl: `http://localhost:5000/api/status/123`,
//             redirectMode: 'POST',
//             mobileNumber: 9969588138,
//             paymentInstrument: {
//                 type: 'PAY_PAGE'
//             }

//      }
//     };
        
//   axios
//     .request(options)
//       .then(function (response) {
//             console.log(response.data);
//         })
//         .catch(function (error) {
//             console.error(error);
//         });
// }




// const phonePeAPICall = async () => {
//   // Replace with your actual credentials and API endpoint
//   const clientId = 'M223X2ED0YPC5';
//   const clientSecret = '415eb9b1-354a-41d1-b845-b04b7d88b3c5';
//   const phonePeApiEndpoint = 'https://api.phonepe.com/v3/payment';

//   // Authenticate and obtain an access token
//   const getAccessToken = async () => {
//     const response = await fetch('https://api.phonepe.com/oauth/token', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//       body: new URLSearchParams({
//         'grant_type': 'client_credentials',
//         'client_id': clientId,
//         'client_secret': clientSecret,
//       }),
//     });

//     const data = await response.json();
//     return data.access_token;
//   };

//   try {
//     const accessToken = await getAccessToken();
//     console.log('Access Token:', accessToken);

//     // Make a sample API request
//     // const response = await fetch(phonePeApiEndpoint, {
//     //   method: 'POST',
//     //   headers: {
//     //     'Content-Type': 'application/json',
//     //     'Authorization': `Bearer ${accessToken}`,
//     //   },
//     //   body: JSON.stringify({
//     //     // Replace with your actual request parameters
//     //     amount: 100.0,
//     //     currency: 'INR',
//     //     order_id: 'your_order_id',
//     //     redirect_url: 'https://genzvision.com',
//     //     customer_id: 'customer123',
//     //     email: 'customer@example.com',
//     //     phone: '1234567890',
//     //   }),
//     // });

//     // const responseData = await response.json();
//     // console.log('PhonePe API Response:', responseData);
//   } catch (error) {
//     console.error('Error:', error);
//   }
// };












const phonePay = (data)=>{
// const options = {
//   method:  'post',
//   url: 'https://api-preprod.phonepe.com/apis/hermes',
//   headers: {
//     accept: 'text/plain',
    
//  'Content-Type' : 'application/json' 
//     },
//      data: {

//      }
//     };
       
//     cors( axios
//       .request(options)
//         .then(function (response) {
//               console.log(response.data);
//           })
//           .catch(function (error) {
//               console.error(error);
//           }))

axios.post('https://us-central1-genzvision-8e0d5.cloudfunctions.net/phonePayPayment', {...data}).then(res => {  

console.log("resssssssss" +res.data)

window.location = res.data;

})
.catch(error => {
    console.error(error);
});   
 
}








  
  const handleSaveData = () => {
    // Get a reference to the database
    const data ={
      name: 'Waleed',
      amount: payment,
      number: '7498608775',
      MUID: "MUID" + Date.now(),
      transactionId:"MUID" + Date.now().valueOf(),
      email:formData.email,
    }
const dataRef = ref(db,'users/'+data.transactionId);
    

    // Reference to the data node in the database
    phonePay(data)

    // Push data to the database
    set(dataRef,{
      email: formData.email, 
    firstName: formData.firstName,
    phoneNumber: formData.phoneNumber,
    postalCode: formData.postalCode,
    couponCode:formData.couponCode.toUpperCase(),
    date:moment().get().valueOf(),
    success:false

    }).catch((data)=>{
      console.log("Error occured",data)
    });

    // Clear the input field after saving data
  };
  const sendEmail =  (email) => {
    console.log("Enaul get "+formData.email+ emailState)
    try {
      const response =  axios.post(
        'https://us-central1-genzvision-8e0d5.cloudfunctions.net/sendEmailWithAttachment1',
        {
          to: formData.email,
          subject: '[Confidential]- Copy of Work, politics & growth - by the Gen Z Vision community',
        //   message: "Dear Professional,  "+<br/>+
        //     "Hope this email finds you well." +<br/>+

        //  " As a community, we are happy to see your interest in learning the tips & tricks for your professional life. Attached is your personal copy of Work, politics & growth - by the Gen Z Vision community."+<br/>+
          
        //  " Read through & share your feedback here."+<br/>+
          
        //  " Show your support to us on social media"+<br/>+
        //   "Youtubea"+<br/>+
        //   "Instagram"+<br/>+
          
        //  " Want to become part of the community, please fill out here"+<br/>+
          
        //  " Regards,"+<br/>+
        //  " Gen Z Vision Community. "

        message:`
        
        
        <p>Dear Professional,<br><br>
Hope this email finds you well.<br><br>

As a community, we are happy to see your interest in learning the tips & tricks for your professional life. Attached is your personal copy of Work, politics & growth - by the Gen Z Vision community.<br><br>

Read through & share your feedback <a href='https://docs.google.com/forms/d/e/1FAIpQLSdkGSo_DZUE3-ZbB0rfd9IotLqx97jUZ8CLkCbA2qq10dHpww/viewform'>here.</a><br><br>

Show your support to us on social media<br>
    <a
          href='https://www.youtube.com/channel/UCgzigpQe-C3hJxolZZrTGSw'
          >Youtube</a><br/>
          
          <a
          href='https://www.facebook.com/profile.php?id=61555974775883&mibextid=ZbWKwL'
          >Facebook</a><br/>
    <a href='https://www.instagram.com/genzvisionofficial/'>
Instagram</a><br/><br>

Want to become part of the community, please fill out <a href='https://docs.google.com/forms/d/e/1FAIpQLSdUg1MsUnsnHoIzPlsaftsQaUglbQ80FAMESk1gvp5axsvURg/viewform' >here</a><br><br>

Regards,<br>
Gen Z Vision Community.</p>`
// message: `This is a plain text message. <br/>`,
//   //         html:
//   //         `
//   //   <p>Hello,</p>
//   //   <p>This is a multi-line email message:</p>
//   //   <p>message</p>
//   //   <p>You can visit our website <a href="https://example.com">here</a>.</p>
//   //   <p>Thank you!</p>
//   // `
,
  // html:`<p>Hello, this is a test HTML email with special characters &amp; symbols.</p>`
         
        },headers,
        
      ).then((dataREsponsee)=>{
        

      console.log(dataREsponsee);

      });
   

    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name == 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsValid(emailRegex.test(formData.email));
    }
    if (name == 'phoneNumber') 
      {
        if (e.target.value.length!=10) {
          setPhoneNumber(false) 
        }else{
          setPhoneNumber(true) 

        }
    }

    if (name == 'couponCode' && value.length >0  ) {
      if (String(value).toUpperCase() === 'GENZVISION' || String(value).toUpperCase() === 'SHAMEETHA50' || String(value).toUpperCase() === 'RHYTHM50' || String(value).toUpperCase() === 'PARUL50' || String(value).toUpperCase() === 'SHEETAL50' || String(value).toUpperCase() === 'REETU50' || String(value).toUpperCase() === 'GATLEEN50') {
      setCoupon('valid')
      setPayment(49900)
      setPaymentAmount(499)
      }
      else if (String(value).toUpperCase() === 'ABHITASH50') {
        setCoupon('valid')
      setPayment(49900)
      setPaymentAmount(499)
      }
      else if (String(value).toUpperCase() === 'POOJAM50') {
        setCoupon('valid')
        setPayment(49900)
        setPaymentAmount(499)
      }
      else if (String(value).toUpperCase() === '15276959') {
        setCoupon('valid')
        setPayment(100)
        setPaymentAmount(1)
      }
      
      else{
        setCoupon('inValid')
        setPayment(99900)
        setPaymentAmount(999)
      }
      
      
    }
    else if(name == 'couponCode') {
      // setPayment(999)

    }
  
   
  };

  
  const handleSubmit = (e) => {

    e.preventDefault();
    // console.log('Form submitted:', formData.email+"   "+Object.values(e));

    // handleSaveData();




    if (isValid && phoneNumberValid) {
      navigate('/')
      setEmailState(formData.email)
    handlePayment(formData.email)  
    }





    
    
    





    // sendEmail("wm")
    // phonePeAPICall()

    // sendEmail("")
      
    // sendEmail(formData.email)
    // You can add form submission l ogic here
  };

  return (
    <div className="form-container">

<br/>
      <h2 style={{color:'white'}}>FORM</h2>
      <form onSubmit={handleSubmit}>
      {/* <form onSubmit={()=>phonePay()}> */}
      <div>
        <br/>
          <label>Email address:*</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          {isValid ? (
       <></>
      ) : (
        <p style={{ color: 'red' }}>Please enter a valid email.</p>
      )}
        </div>
        <div>
          <label>Full name:*</label>
          <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
        </div>
        <div>
          <label>Phone number:*</label>
          <input type="number" maxLength={10} minLength={10}  name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
          {!phoneNumberValid? (
        <p style={{ color: 'red' }}>Please enter correct mobile number</p>
      ):null}
        
        </div>
        <div>
          <label>ZIP/Postal code:*</label>
          <input type="number"  name="postalCode" value={formData.postalCode} onChange={handleChange} required  />
        </div>
        <div>
          <label>Coupon Code:</label>
          <input type="text" name="couponCode" value={formData.couponCode} onChange={handleChange}  />
          {isCoupon=='valid' ? (
       <p style={{ color: 'green' }}>Coupon Applied.</p>
      ) :isCoupon==='inValid'? (
        <p style={{ color: 'red' }}>Coupon Invalid.</p>
      ):null}
        </div>



        {isCoupon==='valid' ? (
          <div class="billing-container">
    <div class="item">
      <span>Name:</span>
      <span>{formData.couponCode}</span>
    </div>
    <div class="item">
      <span>Price:</span>
      <span>999</span>
    </div>
    <div class="item">
      <span>Discount %:</span>
      <span>{formData.couponCode ==='GENZVISION'?'50%':'50%'}</span>
    </div>
    <div class="item">
      <span>Total:</span>
      <span>{paymentAmount}</span>
    </div>
  </div>

        ): null}
        
<br/>


           <a type="submit" ><button disabled={loading} >         {loading ? 'Loading...' : 'Submit'}</button>
           {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
           </a>

      
       <div className="toast" data-autohide="false">
    
    <div className="toast-body">
          <button type="button" className="ml-2 mb-1 close" data-dismiss="toast">&times;</button>

      Some text inside the toast body
    </div>
    </div>
      </form>

    
    </div>
  );
};

export default ResponsiveForm;
