import India from '../images/India.png'
import US from '../images/US.png'
import CA from '../images/CA.png'
import UK from '../images/UK.png'
import AU from '../images/AU.png'
import DE from '../images/DE.png'
import MM from '../images/MM.png'
import Book from '../images/Book.png'
import './Globe.css'
import { useEffect, useState } from 'react'

const Globe = () => {

  const [SmallScreen, setSmallScreen] = useState(null)
  useEffect(() => {
    const screenWidth = window.screen.width;

    console.log("screenWidth"+screenWidth)
    if (screenWidth < 768) {
      setSmallScreen(true)
      // Do something for small screens
    } else {
      setSmallScreen(false)

      console.log('Large screen!');
      // Do something for large screens
    }
  
  })


    return (
<div className='aboutUS-section'  >
<br/>
<br/>
<div className="sectionTitleGlobe">Making Difference across the Globe</div>
<br/>

{!SmallScreen?
  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
<img
          className="GblobeImage"
            alt="Facebook"
            src={India}
          />
       <img
          className="GblobeImage"
            alt="Facebook"
            src={US}
          /> 
               <img
          className="GblobeImage"
            alt="Facebook"
            src={CA}
          /> 
               <img
          className="GblobeImage"
            alt="Facebook"
            src={UK}
          /> 
                 <img
          className="GblobeImage"
            alt="Facebook"
            src={AU}
          /> 
                  <img
          className="GblobeImage"
            alt="Facebook"
            src={DE}
          /> 
                    <img
          className="GblobeImage"
            alt="Facebook"
            src={MM}
          /> 
          
</div>
:
<>
<div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
<img
          className="GblobeImage"
            alt="Facebook"
            src={India}
          />
       <img
          className="GblobeImage"
            alt="Facebook"
            src={US}
          /> 
               <img
          className="GblobeImage"
            alt="Facebook"
            src={CA}
          /> 
            
          
</div>
<br/>
<div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>

<img
          className="GblobeImage"
            alt="Facebook"
            src={UK}
          /> 
                 <img
          className="GblobeImage"
            alt="Facebook"
            src={AU}
          /> 
                  <img
          className="GblobeImage"
            alt="Facebook"
            src={DE}
          /> 
                 
</div>
<br/>

<div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>

<img
          className="GblobeImage"
            alt="Facebook"
            src={MM}
          /> 
</div>
</>
}

<br/>


<p
className='tableTextGlobe'>

"Upon purchasing this copy, you will be entitled to receive <span style={{color:'#FF9F1A'}}>complimentary updates</span> whenever new tips and strategies are added. This offer is ongoing, ensuring you have the latest insights for your professional journey. Your support strengthens our community, and we are committed to standing by you every step of the way.<br/><br/>
We are confident that you’ll find great value in your purchase. Should you wish to provide<br/>feedback or join our community, simply follow the link within the e-book.<br/><br/>
<span style={{color:'#FFA31A'}}>Wishing you success in all your endeavors."</span><br/><br/>

</p>
<div style={{display:'flex', flexDirection:'row', marginRight:'2vw',marginLeft:'2vw' }}>
<img
          className="GVIcon"
            alt="Facebook"
            src={Book}

          />
          <div
className='tableTextGlobeColor'>
          

            <p>Use GENZVISION coupon code at the checkout </p>
            <a href='/form'> <button  className="buttonStyle">Get your copy</button></a>

            </div>
</div>

<br/>
</div>
    )}

export default Globe;
