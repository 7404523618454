import toxicColleagues from '../images/toxicColleagues.png'
import micromanagers from '../images/micromanagers.png'
import creditTakers from '../images/creditTakers.png'
import passiveAggressors from '../images/passiveAggressors.png'
import narcissists from '../images/narcissists.png'
import './BeAwareOf.css'

const BeAwareOf = () => {
    return (
<div   className='aboutUS-section'> 
  <br/>
  <br/>
<h2 className="sectionTitle">Be Aware Of</h2>
<br/>
<div 
className='imageLayoutBAO'
   
>
  <div className='TwoSecLayout'>
  
  <div>
    
    <img
          className="beAwareOfImage"

            alt="Facebook"
            src={toxicColleagues}


          />
          </div>
          <div className='tableText'>
          <p style={{color:'#FFA31A'}}>
          Toxic Colleagues
          </p>
          <p >
          Those who spread negativity, gossip, or  undermine others.</p>
          </div>
          
  </div>


  <div className='TwoSecLayout'>
  <div>

<img
          className="beAwareOfImage"

            alt="Facebook"
            src={micromanagers}


          />
</div>
          <div  className='tableText'>
          <p style={{color:'#FFA31A'}}>

          Micromanagers
          </p>
          <p >
          Supervisors who overly  control or scrutinize every detail of your work.
          </p>
          </div>
          
</div>

</div>
<br/>



<div 
className='imageLayoutBAO'

>

<div className='TwoSecLayout'>
<img
          className="beAwareOfImage"

            alt="Facebook"
            src={creditTakers}


          />
          <div  className='tableText'>
          <p style={{color:'#FFA31A'}}>

          Credit Takers
          </p>
          <p >
          Individuals who take credit for others' ideas or efforts.
          </p>
          </div>
          
</div>
<div className='TwoSecLayout'>
<img
          className="beAwareOfImage"

            alt="Facebook"
            src={passiveAggressors}


          />
          <div className='tableText' >
          <p style={{color:'#FFA31A'}}>

          Passive-Aggressors
          </p>
          <p >
          Those who use indirect  resistance or subtle negativity.
          </p>
          </div>
          
</div>
</div>
<br/>




<div 
className='imageLayoutBAO'

>

<div className='TwoSecLayout'>
<img
          className="beAwareOfImage"

            alt="Facebook"
            src={narcissists}


          />
          <div className='tableText' >
          <p style={{color:'#FFA31A'}}>

          Narcissists
          </p>
          <p >
          Colleagues who prioritize their  interests and often manipulate situations for personal gain.
          </p>
          </div>

          
</div>
<div className='TwoSecLayoutButton'>

<a href='/form'> <button  className="buttonStyle">Get your copy</button></a>  
</div>


</div>
<br/>
</div>)
}



export default BeAwareOf;