import observingPeople from '../images/observingPeople.png'
import teamBonding from '../images/teamBonding.png'
import bondingOutsideTeam from '../images/bondingOutsideTeam.png'
import visibilityCredit from '../images/visibilityCredit.png'
import socialMediaNetworking from '../images/socialMediaNetworking.png'
import dealingBullying from '../images/dealingBullying.png'
import navigatingPolitics from '../images/navigatingPolitics.png'
import bondingHR from '../images/bondingHR.png'
import teamHandling from '../images/teamHandling.png'
import partyTips from '../images/partyTips.png'
import datingTips from '../images/datingTips.png'
import hikePromotions from '../images/hikePromotions.png'
import usingNewTechnologies from '../images/usingNewTechnologies.png'
import bonusTips from '../images/bonusTips.png'

import  './TableOfContent.css'
import { useEffect, useState } from 'react'

const TableOfContentApp = () => {

  const [SmallScreen, setSmallScreen] = useState(null)
  useEffect(() => {
    const screenWidth = window.screen.width;

    console.log("screenWidth"+screenWidth)
    if (screenWidth < 768) {
      setSmallScreen(true)
      // Do something for small screens
    } else {
      setSmallScreen(false)

      console.log('Large screen!');
      // Do something for large screens
    }
  
  })

    return (
<div  className='TOfContent-section'>
<br/>
<br/>
<br/>
<h2 className="sectionTitle">Table of Content</h2>
<br/>
{
  !SmallScreen?
<div

className='imageLayout'
>


<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={observingPeople}


          />
          <p className='tableTextToC' > Observing People</p>
</div>
<div style={{minWidth:'20vw'}} >


           <img
          className="ToCImage"

            alt="Facebook"
            src={teamBonding}


          />
          < p className='tableTextToC' >Team Bonding</p>
</div>
<div style={{minWidth:'20vw'}} >


           <img
          className="ToCImage"

            alt="Facebook"
            src={bondingOutsideTeam}


          />
          <p className='tableTextToC' >Bonding Outside Team</p>
</div>
<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={visibilityCredit}


          />
          <p className='tableTextToC' >Visibility & Credit</p>
</div>


</div>:
<div

className='imageLayout'
>


<div style={{minWidth:'30vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={observingPeople}


          />
          <p className='tableTextToC' > Observing People</p>
</div>
<div style={{minWidth:'30vw'}} >


           <img
          className="ToCImage"

            alt="Facebook"
            src={teamBonding}


          />
          < p className='tableTextToC' >Team Bonding</p>
</div>
<div style={{minWidth:'30vw'}} >


           <img
          className="ToCImage"

            alt="Facebook"
            src={bondingOutsideTeam}


          />
          <p className='tableTextToC' >Bonding Outside Team</p>
</div>



</div>
}

{
  !SmallScreen?
<div
className='imageLayout'

>



<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={socialMediaNetworking}


          />
          <p className='tableTextToC' >Social Media &  <br/>Networking</p>
</div>

<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={dealingBullying}


          />
          <p className='tableTextToC' >Dealing Bullying </p>
</div>


<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={navigatingPolitics}


          />
          <p className='tableTextToC' >Navigating Politics  </p>
</div>
<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={bondingHR}


          />
          <p className='tableTextToC' >Bonding with HR  </p>
</div>
  </div>:
  <div
  className='imageLayout'
  
  >

<div style={{minWidth:'30vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={visibilityCredit}


          />
          <p className='tableTextToC' >Visibility & Credit</p>
</div>
   
  
  
  <div style={{minWidth:'30vw'}} >
  
             <img
            className="ToCImage"
  
              alt="Facebook"
              src={socialMediaNetworking}
  
  
            />
            <p className='tableTextToC' >Social Media &  <br/>Networking</p>
  </div>
  
  <div style={{minWidth:'30vw'}} >
  
             <img
            className="ToCImage"
  
              alt="Facebook"
              src={dealingBullying}
  
  
            />
            <p className='tableTextToC' >Dealing Bullying </p>
  </div>
  
  
  
    </div>
}



{
  !SmallScreen?
   
  <div
className='imageLayout'

>




<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={teamHandling}


          />
          <p className='tableTextToC' >Team Handling</p>
</div>

<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={partyTips}


          />
          <p className='tableTextToC' >Party Tips </p>
</div>
<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={datingTips}


          />
          <p className='tableTextToC' >Dating Tips</p>
</div>

<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={hikePromotions}


          />
          <p className='tableTextToC' >Hike  Promotions</p>
</div>



  </div>
:  <div
className='imageLayout'

>
<div style={{minWidth:'30vw'}} >
  
             <img
            className="ToCImage"
  
              alt="Facebook"
              src={navigatingPolitics}
  
  
            />
            <p className='tableTextToC' >Navigating Politics  </p>
  </div>
  <div style={{minWidth:'30vw'}} >
  
             <img
            className="ToCImage"
  
              alt="Facebook"
              src={bondingHR}
  
  
            />
            <p className='tableTextToC' >Bonding with HR  </p>
  </div>



<div style={{minWidth:'30vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={teamHandling}


          />
          <p className='tableTextToC' >Team Handling</p>
</div>



  </div>
}


{
  !SmallScreen?


  <div
className='imageLayout'

>
<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={usingNewTechnologies}


          />
          <p className='tableTextToC' >Using New <br/> Technologies</p>
</div>

<div style={{minWidth:'20vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={bonusTips}


          />
          <p className='tableTextToC' >Bonus Tips</p>
</div>
<div style={{minWidth:'20vw'}} >

<a href='/form'> <button  className="buttonStyle">Get your copy</button></a>

</div>
<div style={{minWidth:'20vw'}} >
</div>

</div>
: <div
className='imageLayout'

>

<div style={{minWidth:'30vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={partyTips}


          />
          <p className='tableTextToC' >Party Tips </p>
</div>
<div style={{minWidth:'30vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={datingTips}


          />
          <p className='tableTextToC' >Dating Tips</p>
</div>

<div style={{minWidth:'30vw'}} >

           <img
          className="ToCImage"

            alt="Facebook"
            src={hikePromotions}


          />
          <p className='tableTextToC' >Hike  Promotions</p>
</div>


</div>}

{
  !SmallScreen?
  null:
  <div className='imageLayout'>
    
<div style={{minWidth:'30vw'}} >

<img
className="ToCImage"

 alt="Facebook"
 src={usingNewTechnologies}


/>

<p className='tableTextToC' >Using New <br/> Technologies</p>
</div>

<div style={{minWidth:'30vw'}} >

<img
className="ToCImage"

 alt="Facebook"
 src={bonusTips}


/>
<p className='tableTextToC' >Bonus Tips</p>
</div>
<div style={{minWidth:'30vw'}} >

<a href='/form'> <button  className="buttonStyle">Get my copy</button></a>

</div>


</div>
}

<br/>


</div>
    )
}



export default TableOfContentApp;