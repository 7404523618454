
import { useEffect, useState } from 'react'
import aboutUsImage from '../images/aboutUsImage.png'
import './AboutUS.css'
const AboutUS = () => {

  const [SmallScreen, setSmallScreen] = useState(null)

  useEffect(() => {
    const screenWidth = window.screen.width;
    if (screenWidth < 768) {
      setSmallScreen(true)
      // Do something for small screens
    } else {
      setSmallScreen(false)

      console.log('Large screen!');
      // Do something for large screens
    }
  
  })
    return (
      
<div  className='aboutUs-section'>

      <br/>
            
<h2 className="sectionTitleAbout" >About Us</h2><br/>
          <p className="contentDescAboutUS">
         <span style={{color:'#FF9F1A'}}> GenZvision </span> is a thriving community of experienced professionals from various industries across the globe, all united by <span style={{color:'#FF9F1A'}}>one vision: 'No more victims of office politics.'</span> 
<br></br>
<br></br>
To stop the young professionals from becoming prey to office politics the community has put up various tips & tricks that can used at the workplace to tackle office politics & build a successful career.
          </p>
          <br></br>

          <div  className='ImageAlignAboutUS'>
          <img
          className="aboutUsBg"

            alt="Facebook"
            src={aboutUsImage}


          />
           <div >
           {SmallScreen?<br/>:null}
           <p className='ImageCaptionAbout' >Explore the 
            {!SmallScreen?<br/>:null}
            
            <span  style={{color:'#FFA31A'}}> 'Table of Contents' </span>
            {!SmallScreen?<br/>:null}

             to discover the 
             {!SmallScreen?<br/>:null}

              actionable tips that 
              {!SmallScreen?<br/>:null}

              awaits you.</p>
           </div>
          </div>
          <br></br>
        
</div>)}

export default AboutUS;