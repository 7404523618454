// import React from 'react';
// import {db} from './firebase';
// import { getDatabase, ref, set,update,get, orderByChild } from "firebase/database";
// import moment from 'moment';
// import { useParams } from 'react-router-dom';

// class Dashboard extends React.Component {
//   constructor(props) {
//     super(props);
 

//     // Sample data for names and dates
//     this.state = {
//       items: [
//         { firstName: 'John', date: '2024-03-24' },
//         { firstName: 'Alice', date: '2024-03-23' },
//         { firstName: 'Bob', date: '2024-03-22' }
//       ],
//     };
//   }
// componentDidMount()
// {
//     const dataRef = ref(db, '/users'); // Replace 'your_data_path' with the path to your data in the database

//     get(dataRef).then((snapshot) => {
//       if (snapshot.exists()) {
//         const data = snapshot.val();
//         console.log("Data from Firebase:", data);
//         const dataListArray = Object.keys(data).map(key => ({ id: key, ...data[key] }));
//           this.setState({items:dataListArray})
        
//         // Do something with the fetched data, e.g., set it in state
//         // setState(data);
//       } else {
//         console.log("No data available");
//       }
//     }).catch((error) => {
//       console.error("Error fetching data:", error);
//     });
// }
//   render() {
  
//     return (
//       <div style={{ backgroundColor: 'black', color: 'white', padding: '20px', border: '2px solid white' }}>
//         <h1>List of Names and Dates</h1>
//         <ul>
//           {this.state.items.filter(data=> data.couponCode== couponCode).map((item, index) => (
//             <li key={index}>
//               <strong>{item.firstName}</strong> - {moment(item.date).format("DD MMM YYYY hh:mm a")}
//             </li>
//           ))}
//         </ul>
//       </div>
//     );
//   }
// }

// export default Dashboard;


import React, { useEffect, useState } from 'react'
import {db} from './firebase';
import { getDatabase, ref, set,update,get, orderByChild } from "firebase/database";
import moment from 'moment';
import { useParams } from 'react-router-dom';
import './Dashboard.css'; // Import your CSS file for styling


const Dashboard = () => {



  const { couponCode } = useParams();
  const [items, setitems] = useState([])
  const [couponCode1, setcouponCode] = useState(couponCode)


useEffect(() => {
      const dataRef = ref(db, '/users'); // Replace 'your_data_path' with the path to your data in the database

    get(dataRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log("Data from Firebase:", data);
        const dataListArray = Object.keys(data).map(key => ({ id: key, ...data[key] }));
          setitems(dataListArray)
        
        // Do something with the fetched data, e.g., set it in state
        // setState(data);
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error("Error fetching data:", error);
    });

}, [])


  return (
    <div className="dashboard-container"><br/>
    <h2 className="dashboard-heading">Dashboard</h2>
    <h2 className="dashboard-content">Number of professionals reached </h2>
    
    <p className="coupon-code">{items.filter(data=> data.couponCode == couponCode1.toUpperCase() && data.success).length}</p>
    <div className="data-list">
        <ul style={{margin:20, padding:0}}>
          {items.filter(data=> data.couponCode== couponCode1 && data.success).map((item, index) => (
            <li key={index} style={{margin:10,padding:10,flexDirection:'row',display:'flex',justifyContent:'space-around',alignItems:'center',borderColor:'#fff',borderWidth:2,border:'solid', borderRadius:10}}>
              <strong>{item.firstName}</strong>  
              <strong>{moment(item.date).format("DD MMM YYYY hh:mm a")}</strong>
            </li>
          ))}
          
        </ul>
        </div>
      </div>
  )
}

export default Dashboard