import React, { Component } from 'react'

export class Shipping extends Component {
  render() {
    return (
      <div
  style={{padding:40}}
      
      >


<h1><b>Shipping & Delivery Policy</b></h1> 

 <br/>
 <br/>

Shipping is not applicable for business. 
      </div>
    )
  }
}

export default Shipping