import graph from '../images/graph.png'

import './Statistics.css'
const Statistics = () => {
    return (
<div  className='aboutUS-section' >
  <br/>
<div className='imageAndDesc'>
  <div>
<img
          className="statisticBg"

            alt="Facebook"
            src={graph}


          />
          </div>
          <div className='ImageDesc'>
          <p className='contentDescWarning'>
          <span style={{color:'#FFA31A'}}>Women:</span> Women frequently face
          challenges like exclusion from 
           key networks, underestimation, 
            and bias, making them more
             vulnerable to the negative 
              impacts of office politics in many countries.
          </p>
          </div>
</div>
<br/>
<br/>

<p className='contentDescWarning'>
<span style={{color:'#FFA31A'}}>Men: </span>Men might experience different forms of office politics, such as power struggles or competitive pressures, which can also affect their work satisfaction and mental health.</p>

<br/>


</div>)
}



export default Statistics;