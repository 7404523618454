
import { useEffect, useState } from 'react'
import warningImage from '../images/warningImage.png'
import './Warning.css'
const Warning = () => {
  const [SmallScreen, setSmallScreen] = useState(null)
  useEffect(() => {
    const screenWidth = window.screen.width;
    if (screenWidth < 768) {
      setSmallScreen(true)
      // Do something for small screens
    } else {
      setSmallScreen(false)

      console.log('Large screen!');
      // Do something for large screens
    }
  })

    return (
        <div  className='aboutUS-section'>
            <br/>
            <br/>
          <h2 className="sectionTitle">Warnings</h2> <br/>
          <p className="contentDescWarning">Competition for growth is real. Most of the time you compete with your colleagues & few times you compete to be part of the management team. Remember, no one is your FRIEND at the workplace. Don’t be fooled by the word <span style={{color:'#FF9F1A'}}>"FRIENDS" </span> at the workplace.
<br></br>
<br></br>

            </p>

            <div  className='ImageAlign'>
          <img
          className="warningBg"

            alt="Facebook"
            src={warningImage}


          />
           <div >
           <p className='ImageCaptionWarning'>
           Never share this copy of content with your 
            colleagues. They might use the trips & tricks
              against you. End of the day its a individual journey.
           
           </p>

<br/>
            </div>
            </div>
            <br/>

        <div  style={{display:'flex',justifyContent:'space-around',flexDirection:'row',alignItems:'center',}}>
  <a href='/form'> <button  className="buttonStyle">Get my copy</button></a> 
  </div>
  <br/>
        </div>
    )}
export default Warning;
