import stressAnxiety from '../images/stressAnxiety.png'
import lossMotivation from '../images/lossMotivation.png'
import selfDoubt from '../images/selfDoubt.png'
import frustrationAnger from '../images/frustrationAnger.png'
import isolation from '../images/isolation.png'
import fatigueBurnout from '../images/fatigueBurnout.png'
import headaches from '../images/headaches.png'
import sleep from '../images/sleep.png'
import weakened from '../images/weakened.png'
import digestiveIssues from '../images/digestiveIssues.png'
import './EffectsWorkPolitics.css'
import { useEffect, useState } from 'react'

const EffectsOfWorkPolitics = () => {

  const [SmallScreen, setSmallScreen] = useState(null)
  useEffect(() => {
    const screenWidth = window.screen.width;

    console.log("screenWidth"+screenWidth)
    if (screenWidth < 768) {
      setSmallScreen(true)
      // Do something for small screens
    } else {
      setSmallScreen(false)

      console.log('Large screen!');
      // Do something for large screens
    }
  
  })

    return (
<div className='aboutUS-section' > 
  <br/>
  <br/>
<div className="WOWPTitle">Effects of work politics on Mental & Physical Health</div>
<br/>

<h6 className="EOWPTitleColor">Mental Health</h6>
<br/>
{!SmallScreen?
  <div style={{display:'flex', flexDirection:'row',  justifyContent:'space-around',}}>

<div>

<img
      className="aboutUsStyle"

        alt="Facebook"
        src={stressAnxiety}
        

      />
              <br/>
              <p className='tableTextPolitics'>Stress and Anxiety</p>
</div>
     
<div>

<img
      className="aboutUsStyle"
      

        alt="Facebook"
        src={lossMotivation}
      />
      <br/>
      <p className='tableTextPolitics'>Loss of Motivation</p>
</div>
<div>

<img
      className="aboutUsStyle"
      

        alt="Facebook"
        src={selfDoubt}
      />
      <br/>
      <p className='tableTextPolitics'>Self-Doubt and <br/>Low Self-Esteem</p>
</div>

<div>

<img
      className="aboutUsStyle"
      

        alt="Facebook"
        src={frustrationAnger}
      />
      <br/>
      <p className='tableTextPolitics'>Frustration and Anger</p>
</div>
<div>

<img
      className="aboutUsStyle"
      

        alt="Facebook"
        src={isolation}
      />
      <br/>
<p className='tableTextPolitics'>Isolation</p>
</div>

</div>:
<>

<div style={{display:'flex', flexDirection:'row',  justifyContent:'space-between'}}>

<div>

<img
      className="aboutUsStyle"

        alt="Facebook"
        src={stressAnxiety}
        

      />
              <br/>
              <p className='tableTextPolitics'>Stress and Anxiety</p>
</div>
     
<div>

<img
      className="aboutUsStyle"
      

        alt="Facebook"
        src={lossMotivation}
      />
      <br/>
      <p className='tableTextPolitics'>Loss of Motivation</p>
</div>


</div>


<div style={{display:'flex', flexDirection:'row',  justifyContent:'space-between'}}>
<div>

<img
      className="aboutUsStyle"
      

        alt="Facebook"
        src={selfDoubt}
      />
      <br/>
      <p className='tableTextPolitics'>Self-Doubt and <br/>Low Self-Esteem</p>
</div>

<div>

<img
      className="aboutUsStyle"
      

        alt="Facebook"
        src={frustrationAnger}
      />
      <br/>
      <p className='tableTextPolitics'>Frustration and Anger</p>
</div>


</div>
<div style={{display:'flex', flexDirection:'row', }}>
<div>

<img
      className="aboutUsStyle"
      

        alt="Facebook"
        src={isolation}
      />
      <br/>
<p className='tableTextPolitics'>Isolation</p>
</div>
</div>


</>}

<br/>
<h6 className="EOWPTitleColor">Physical Health</h6>
<br/>



{!SmallScreen?
  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>


<div>

<img
          className="aboutUsStyle"
          

            alt="Facebook"
            src={fatigueBurnout}
           
          />
          <p className='tableTextPolitics'>Fatigue and Burnout</p>
   
    </div>


    <div>

<img
          className="aboutUsStyle"
          

            alt="Facebook"
            src={headaches}
          />
          <br/>
          <p className='tableTextPolitics'>Headaches and Muscle <br/>Tension</p>

    </div>


    <div>

<img
          className="aboutUsStyle"
          

            alt="Facebook"
            src={sleep}
          />
          <br/>
          <p className='tableTextPolitics'>Sleep Disturbances</p>
    </div>



    <div>

<img
          className="aboutUsStyle"
          

            alt="Facebook"
            src={digestiveIssues}
          />
          <br/>
          <p className='tableTextPolitics'>Digestive Issues</p>
    </div>

    <div>

<img
          className="aboutUsStyle"
          

            alt="Facebook"
            src={weakened}
          />
          <br/>
          <p className='tableTextPolitics'>Weakened Immune System</p>
    </div>

   
</div>
:
<>
<div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>


<div>

<img
          className="aboutUsStyle"
          

            alt="Facebook"
            src={fatigueBurnout}
           
          />
          <p className='tableTextPolitics'>Fatigue and Burnout</p>
   
    </div>

    <div>

<img
          className="aboutUsStyle"
          

            alt="Facebook"
            src={headaches}
          />
          <br/>
          <p className='tableTextPolitics'>Headaches and Muscle <br/>Tension</p>

    </div>

  

   
</div>


<div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
<div>

<img
          className="aboutUsStyle"
          

            alt="Facebook"
            src={sleep}
          />
          <br/>
          <p className='tableTextPolitics'>Sleep Disturbances</p>
    </div>

    <div>

<img
          className="aboutUsStyle"
          

            alt="Facebook"
            src={digestiveIssues}
          />
          <br/>
          <p className='tableTextPolitics'>Digestive Issues</p>
    </div>

   
</div>

<div style={{display:'flex', flexDirection:'row', justifyContent:'space-between '}}>
<div>

<img
          className="aboutUsStyle"
          

            alt="Facebook"
            src={weakened}
          />
          <br/>
          <p className='tableTextPolitics'>Weakened Immune System</p>
    </div>
</div>


</>
}




<br/>




</div>


)
}



export default EffectsOfWorkPolitics;