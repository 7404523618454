import React, { Component } from 'react'

export class Terms extends Component {
  render() {
    return (
      <div
      style={{padding:40}}
      >


<h1 style={{textDecoration:'underline',}}><b>Terms of use</b></h1> 

Terms of Use for Gen-Z Vision’ Products and Services 
<br/>
<br/>
Last Updated on January 22, 2024 
<br/>
<br/>

BY VISITING Genzvision.com, OR BY PURCHASING PRODUCTS FROM GENZVISION.COM /, YOU ARE CONSENTING TO OUR TERMS OF SERVICE. 
<br/>
<br/>

<h3><b>GENERAL </b></h3> 
<br/>

This site (the “Site”) is developed and owned by Stuffenzy LLP (“COMPANY,” “we” or “us”). By utilizing the Site, you consent to be bound by these Terms of Service and to utilize the Site as per these Terms of Service, our Privacy Policy and any extra terms and conditions that may apply to explicit areas of the Site or to items and administrations accessible through the Site or from COMPANY. Getting to the Site, in any way, regardless of whether robotized or something else, establishes utilization of the Site and your consent to be bound by these Terms of Service.  
<br/>
<br/>

We maintain whatever authority is needed to change these Terms of Service or to force new conditions on utilization of the Site, when necessary, in which case we will post the updated Terms of Service on this site. By proceeding to utilize the Site after we post any such changes, you acknowledge the Terms of Service, as altered. 
<br/>
<br/>

<h3><b>INTELLECTUAL PROPERTY RIGHTS </b></h3>
<br/>

Our Limited License to You.  
<br/>
<br/>

This Site and every one of the materials accessible on the Site are the property of us and additionally our partners or licensors, and are ensured by copyright, trademark, and other protected innovation laws. The Site is given exclusively to your own noncommercial use. You may not utilize the Site or the materials accessible on the Site in a way that establishes an encroachment of our rights or that has not been approved by us. All the more explicitly, except if unequivocally approved in these Terms of Service or by the proprietor of the materials, you may not alter, duplicate, imitate, republish, transfer, post, transmit, decipher, sell, make subsidiary works, misuse, or convey in any way or medium (counting by email or other electronic methods) any material from the Site. You may, in any case, now and again, download as well as print one duplicate of individual pages of the Site for your own, non-business use, given that you keep flawless all copyright and other exclusive takes note.  
<br/>
<br/>

<h3><b>Your License to Us.</b></h3>
<br/>
  

By posting or presenting any material (counting, without restriction, remarks, blog sections, photographs and recordings) to us through the Site, you acknowledge to the following : (I) that you are the proprietor of the material, or are making your posting or accommodation with the express assent of the proprietor of the material; and (ii) that you are Sixteen years old or more. Likewise, when you submit or post any material, you are allowing us, and anybody approved by us, a sovereignty free, never-ending, permanent, non-selective, unlimited, overall permit to utilize, duplicate, alter, transmit, sell, misuse, make subsidiary works from, disseminate, and additionally openly perform or show such material, in entire or to a limited extent, in any way or medium, presently known or in the future created, for any reason. The prior award will incorporate the privilege to misuse any exclusive rights in such posting or accommodation, including, yet not constrained to, rights under copyright, trademark, administration imprint or patent laws under any significant ward. Additionally, regarding the activity of such rights, you award us, and anybody approved by us, the privilege to recognize you as the creator of any of your postings or entries by name, email address or screen name, as we consider proper.  
<br/>
<br/>

You recognize and concur that any commitments initially made by you for us will be considered a “work made for contract” when the work performed is inside the extent of the meaning of a work made for contract in the Copyright Act of India, as changed. Thusly, the copyrights in those works will have a place with COMPANY from their creation. In this way, COMPANY will be esteemed the creator and select proprietor thereof and will reserve the option to abuse any or the majority of the outcomes and continues in any media, presently known or in the future concocted, all through the universe, in interminability, in all dialects, as COMPANY decides. If any of the outcomes and continues of your entries hereunder are not esteemed a “work made for contract” under the Copyright Act of India, as corrected, you thusly, without extra pay, permanently dole out, pass on and move to COMPANY every single restrictive right, including without impediment, all copyrights and trademarks all through the universe, in interminability in each medium, regardless of whether currently known or from this point forward conceived, to such material and all right, title and enthusiasm for and to all such exclusive rights in each medium, whether presently known or in the future contrived, all through the universe, in unendingness. Any posted material which are propagations of earlier works by you will be co-possessed by us.  
<br/>
<br/>

You recognize that COMPANY has the privilege however not the commitment to utilize and show any postings or commitments of any sort and that COMPANY may choose to stop the utilization and show of any such materials (or any segment thereof), whenever under any conditions. 
<br/>
<br/>

<h3><b>LIMITATIONS ON LINKING AND FRAMING</b></h3> 
<br/>

You may establish a hypertext link to the Site so long as the link does not state or imply any sponsorship of your site by us or by the Site. However, you may not, without our prior written permission, frame or link to any of the content of the Site, or incorporate into another website or other service any of our material, content or intellectual property. 
<br/>
<br/>

<h3><b>DISCLAIMERS</b></h3> 
<br/>

All through the Site, we may give connections and pointers to Internet locales kept up by outsiders. Our connecting to such outsider destinations does not infer an underwriting or sponsorship of such locales, or the data, items or administrations offered on or through the destinations. Also, neither we nor partners work or control in any regard any data, items or administrations that outsiders may give on or through the Site or on sites connected to by us on the Site.  
<br/>
<br/>

In the event that pertinent, any sentiments, exhortation, articulations, administrations, offers, or other data or substance communicated or made accessible by outsiders, including data suppliers, are those of the separate creators or merchants, and not COMPANY. Neither COMPANY nor any outside supplier of data ensures the precision, culmination, or value of any substance. Moreover, COMPANY neither supports nor is in charge of the precision and unwavering quality of any sentiment, counsel, or proclamation made on any of the Sites by anybody other than an approved COMPANY agent while acting in his/her official limit.  
<br/>
<br/>

 

THE INFORMATION, PRODUCTS AND SERVICES OFFERED ON OR THROUGH THE SITE AND BY COMPANY AND ANY THIRD-PARTY SITES ARE PROVIDED “AS May be” AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE SITE OR ANY OF ITS FUNCTIONS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT ANY PART OF THIS SITE, INCLUDING BULLETIN BOARDS, OR THE SERVERS THAT MAKE IT AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.  
<br/>
<br/>

WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE SITE OR MATERIALS ON THIS SITE OR ON THIRD-PARTY SITES IN TERMS OF THEIR CORRECTNESS, ACCURACY, TIMELINESS, RELIABILITY OR OTHERWISE.  
<br/>
<br/>

You concur consistently to safeguard, repay and hold innocuous THE COMPANY its offshoots, their successors, transferees, appointees and licensees and their particular parent and auxiliary organizations, specialists, partners, officials, chiefs, investors and workers of each from and against any cases, reasons for activity, harms, liabilities, expenses and costs, including legitimate charges and costs, emerging out of or identified with your break of any commitment, guarantee, portrayal or agreement put forward in this. 
<br/>
<br/>

<h3><b>PURCHASES AND ONLINE COMMERCE</b></h3> 
<br/>

You consent to just buy products or administrations for yourself or for someone else for whom you are lawfully allowed to do as such or for whom you have acquired the express agree to give their name, address, strategy for installment, Mastercard number, and charging data.  
<br/>
<br/>

You consent to be monetarily in charge of all buys made by you or somebody following up for your sake. You consent to utilize our Programs, Products, Services, and Program Materials for real, non-business purposes just and not for theoretical, false, deceitful, or illicit purposes.  
<br/>
<br/>

   

On the off chance that you make a buy from one of our members, or some other individual or organization through a connection gave on or through our Programs, Products or Services (“Merchant”), all data got during your buy or exchange and the majority of the data that you give as a component of the exchange, for example, your Visa number and contact data, might be gathered by the trader and their installment preparing organization too. Your investment, correspondence or business dealings with any member, individual or organization on or through our Programs, Products or Services, and all buy terms, conditions, portrayals or guarantees related with installment, discounts, and additionally conveyance identified with your buy, are exclusively among you and the Merchant. You concur that we will not be mindful or subject for any misfortune, harm, discounts, or different issues of any kind that brought about as the aftereffect of such dealings with a Merchant.  
<br/>
<br/>

You discharge us, our partners, and Merchants from any harms that you bring about, and make a deal to avoid declaring any cases against us or them, emerging from your buy through or utilization of our Website or its Content. 
<br/>
<br/>

<h3><b>REFUND POLICY</b></h3> 
<br/>

Unless otherwise provided by law, you acknowledge that we do not offer refunds for any portion of your payment for any of our one-on-one services, digital products, courses, coaching or masterminds. 
<br/>
<br/>

<h3><b>INTERACTIVE FEATURES</b></h3> 
<br/>

This Site may incorporate an assortment of highlights, for example, announcement loads up, web logs, visit rooms, and email administrations, which enable input to us and continuous collaboration among clients, and different highlights which enable clients to speak with others. Duty regarding what is posted on announcement sheets, web logs, visit rooms, and other open posting regions on the Site, or sent by means of any email benefits on the Site, lies with every client – only you are in charge of the material you post or send. We don’t control the messages, data or records that you or others may give through the Site.  
<br/>
<br/>

It is a state of your utilization of the Site that you don’t:  
<br/>
<br/>

Confine or restrain some other client from utilizing and getting a charge out of the Site.  
<br/>
<br/>

Utilize the Site to mimic any individual or element, or dishonestly state or generally distort your alliance with an individual or substance.  
<br/>
<br/>

Meddle with or disturb any servers or systems used to give the Site or its highlights, or ignore any necessities, methodology, approaches or guidelines of the systems we use to give the Site.  
<br/>
<br/>

Utilize the Site to prompt or urge others to submit illicit exercises or cause damage or property harm to any individual.  
<br/>
<br/>

Increase unapproved access to the Site, or any record, PC framework, or system associated with this Site, by methods, for example, hacking, secret phrase mining or other unlawful methods.  
<br/>
<br/>

Get or endeavor to get any materials or data through any methods not purposefully made accessible through this Site.  
<br/>
<br/>

Utilize the Site to post or transmit any unlawful, compromising, oppressive, slanderous, disparaging, foul, obscene, explicit, profane or disgusting data of any sort, including without confinement any transmissions comprising or empowering conduct that would establish a criminal offense, offer ascent to common obligation or generally damage any neighborhood, commonplace, national or global law.  
<br/>
<br/>

Utilize the Site to post or transmit any data, programming or other material that abuses or encroaches upon the privileges of others, including material that is an attack of security or attention rights or that is ensured by copyright, trademark or other restrictive right, or subsidiary works with deference thereto, without first acquiring consent from the proprietor or rights holder.  
<br/>
<br/>

Utilize the Site to post or transmit any data, programming or other material that contains an infection or other unsafe part.  
<br/>
<br/>

Utilize the Site to post, transmit or in any capacity abuse any data, programming or other material for business purposes, or that contains promoting.  
<br/>
<br/>

 

Utilize the Site to promote or request to anybody to purchase or sell items or administrations, or to make gifts of any sort, without our express composed endorsement.  
<br/>
<br/>

Accumulate for showcasing purposes any email addresses or other individual data that has been posted by different clients of the Site.  
<br/>
<br/>

Organization may host message sheets, talks and other open gatherings on its Sites. Any client neglecting to conform to the terms and states of this Agreement might be removed from and rejected proceeded with access to, the message sheets, talks or other open gatherings later on. Organization or its assigned specialists may expel or change any client made substance whenever in any capacity whatsoever. Message sheets, visits and other open gatherings are proposed to fill in as discourse habitats for clients and supporters. Data and substance posted inside these open gatherings might be given by COMPANY staff, COMPANY’s outside benefactors, or by clients not associated with COMPANY, some of whom may utilize unknown client names. Organization explicitly disavows all obligation and support and makes no portrayal with regards to the legitimacy of any sentiment, exhortation, data or explanation made or showed in these discussions by outsiders, nor are we in charge of any blunders or exclusions in such postings, or for hyperlinks installed in any messages. By no means will we, our offshoots, providers or specialists be obligated for any misfortune or harm brought about by your dependence on data got through these gatherings. The feelings communicated in these discussions are exclusively the conclusions of the members, and don’t mirror the sentiments of COMPANY or any of its auxiliaries or offshoots.  
<br/>
<br/>

Organization has no commitment at all to screen any of the substance or postings on the message sheets, talk rooms or other open discussions on the Sites. Notwithstanding, you recognize and concur that we have irrefutably the privilege to screen the equivalent at our sole circumspection. Furthermore, we maintain whatever authority is needed to change, alter, decline to post or evacuate any postings or substance, in entire or to some extent, in any capacity whatsoever and to uncover such materials and the conditions encompassing their transmission to any outsider so as to fulfill any relevant law, guideline, legitimate procedure or administrative solicitation and to secure ourselves, our customers, backers, clients and guests 
<br/>
<br/>

 

<h3><b>REGISTRATION</b></h3> 
<br/>

To access certain features of the Site, we may ask you to provide certain demographic information including your gender, year of birth, zip code and country. In addition, if you elect to sign-up for a particular feature of the Site, you may also be asked to register with us on the form provided and such registration may require you to provide personally identifiable information such as your name and email address. You agree to provide true, accurate, current and complete information about yourself as prompted by the Site’s registration form. If we have reasonable grounds to suspect that such information is untrue, inaccurate, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof). Our use of any personally identifiable information you provide to us as part of the registration process is governed by the terms of our Privacy Policy. 
<br/>
<br/>

<h3><b>PASSWORDS</b></h3> 
<br/>

If you need a username and password to use certain features of the Site, you will receive a username and password through the Site’s registration process. You are responsible for maintaining the confidentiality of the password and account, and are responsible for all activities (whether by you or by others) that occur under your password or account. You agree to notify us immediately of any unauthorized use of your password or account or any other breach of security, and to ensure that you exit from your account at the end of each session. We cannot and will not be liable for any loss or damage arising from your failure to protect your password or account information. 
<br/>
<br/>

<h3><b>LIMITATION OF LIABILITY</b></h3> 
<br/>

UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE, OUR SUBSIDIARY AND PARENT COMPANIES OR AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE SITE, INCLUDING ITS MATERIALS, PRODUCTS, OR SERVICES, OR THIRD-PARTY MATERIALS, PRODUCTS, OR SERVICES MADE AVAILABLE THROUGH THE SITE, EVEN IF WE ARE ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES. (BECAUSE SOME PROVINCES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN CATEGORIES OF DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH PROVINCES, OUR LIABILITY AND THE LIABILITY OF OUR SUBSIDIARY AND PARENT COMPANIES OR AFFILIATES IS LIMITED TO THE FULLEST EXTENT PERMITTED BY SUCH STATE LAW.) YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT WE ARE NOT LIABLE FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY USER. IF YOU ARE DISSATISFIED WITH THE SITE, ANY MATERIALS, PRODUCTS, OR SERVICES ON THE SITE, OR WITH ANY OF THE SITE’S TERMS AND CONDITIONS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE AND THE PRODUCTS, SERVICES AND/OR MATERIALS. 
<br/>
<br/>

THIS SITE IS CONTINUALLY UNDER DEVELOPMENT AND COMPANY MAKES NO WARRANTY OF ANY KIND, IMPLIED OR EXPRESS, AS TO ITS ACCURACY, COMPLETENESS OR APPROPRIATENESS FOR ANY PURPOSE. 
<br/>
<br/>

PARTICIPANT acknowledges and agrees that no representation has been made by CANUPY INC. OR ITS AFFILIATES and relied upon as to the future income, expenses, sales volume or potential profitability that may be derived from the participation in this PROGRAM. 
<br/>
<br/>

<h3><b>TERMINATION</b></h3> 
<br/>

We may cancel or terminate your right to use the Site or any part of the Site at any time without notice. In the event of cancellation or termination, you are no longer authorized to access the part of the Site affected by such cancellation or termination. The restrictions imposed on you with respect to material downloaded from the Site, and the disclaimers and limitations of liabilities set forth in these Terms of Service, shall survive. 
<br/>
<br/>

<h3><b>OTHER</b></h3> 
<br/>

This Agreement shall be binding upon and inure to the benefit of COMPANY and our respective assigns, successors, heirs, and legal representatives. Neither this Agreement nor any rights hereunder may be assigned without the prior written consent of COMPANY. Notwithstanding the foregoing, all rights and obligations under this Agreement may be freely assigned by COMPANY to any affiliated entity or any of its wholly owned subsidiaries. 
<br/>
<br/>

  

These Terms of Use shall be governed by and construed in accordance with the laws of the province of India and any dispute shall be subject to binding arbitration in India. If any provision of this agreement shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from this agreement and shall not affect the validity and enforceability of any remaining provisions. 
<br/>
<br/>

  

 

        </div>
    )
  }
}

export default Terms