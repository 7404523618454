import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, get } from 'firebase/database'; // For Realtime Database

const firebaseConfig = {
  apiKey: "AIzaSyAEcA7nNUBzgl5cDrXMmxxP2FYD9UsjYG4",
  authDomain: "genzvision-8e0d5.firebaseapp.com",
  databaseURL: "https://genzvision-8e0d5-default-rtdb.firebaseio.com",
  projectId: "genzvision-8e0d5",
  storageBucket: "genzvision-8e0d5.appspot.com",
  messagingSenderId: "241744118133",
  appId: "1:241744118133:web:064ddbafb0a7805f16533c"
};
const app = initializeApp(firebaseConfig);

// For Realtime Database:
const db = getDatabase(app);

// For Firestore:

export { db };