import React, { Component } from 'react'
export class CancelPage extends Component {
  render() {
    return (
        <div
      
        style={{padding:40}}    
        >
  
  
  <h1><b>Refund Policy</b></h1> 
  
  <h2><b>Refund and Cancellation Policy</b></h2> 
  
  Last Updated on 22nd January 2024 
  <br/>
  <br/>
  Thank you for purchasing our workshop/course at genzvision.com/ operated by Stuffenzy LLP. 
  <br/>
  <br/>
  
  Once purchased, our workshops/courses cannot be canceled and are non-refundable.  
  <br/>
  <br/>
  
  You may however choose to join a different batch or let another candidate attend the course instead of you. The completion certificate will only be issued once and in the name of the candidate attending the complete course and passing the required examination.  
  <br/>
  <br/>
  
  If the replacement batch chosen by the candidate has a higher fee, the candidate will have to pay the differential amount.  
  <br/>
  If the replacement batch chosen by the candidate has a lower fee, a credit note will be issued to the candidate for the differential amount. This credit note can be utilized as part / full payment of any other course offered by Stuffenzy LLP. 
  <br/>
  <br/>
  
  If you have any additional questions or would like to request a batch change or candidate name transfer, feel free to contact us on <a href='mailto:genzvisionuser@gmail.com'>genzvisionuser@gmail.com.</a> 
  <br/>
  <br/>
  
  The request for batch change or candidate name transfer should be made at least 48 hours prior to the start of the course, post which you will not be eligible for any changes in batch or candidate name. 
  <br/>
  <br/>
  
  If a candidate fails to attend the workshop/course and/or examination, he/she will be marked absent and no replacement lectures / examinations will be provided. The candidate is also not eligible for a refund. 
  
  <br/>
  <br/>
   
  
        </div>
    )
  }
}

export default CancelPage